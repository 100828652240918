.Home {
  max-width: 320px;
  padding: 20px;

  &__main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &__name {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-size: 24px;
  }

  &__subtitle {
    font-size: 10px;
    margin-bottom: 20px;
  }

  &__links {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;

    &__link {
      font-size: 24px;
      color: #1C1C1C;
      margin-right: 10px;

      &:link,
      &:active,
      &:visited {
        text-decoration: none;
      }

      &:hover {
        color: #474848;
      }
    }
  }

  &__contact {
    margin-bottom: 20px;

    &__detail {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 3px;

      &__icon {
        width: 20px;
      }

      &__text {
        text-align: left;
        width: 100%;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    &__button {
      display: block;
      border-right: 10px;
      padding: 10px;
      border-radius: 4px;
      background-color: #1C1C1C;
      color: #FFF;
    }
  }

}
