.CV {
  font-size: 16px;
  background-color: rgba(#000, 0.1);
  padding: 40px 0;

  @media print {
    padding: 0;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 20px;

    @media print {
      display: none;
    }

    &__button {
      display: block;
      border-right: 10px;
      padding: 10px;
      border-radius: 4px;
      background-color: #1C1C1C;
      color: #FFF;
    }
  }

  &__paper {
    max-width: 720px;
    margin: 0 auto;
    background-color: #FFF;
    box-shadow: 0px 4px 10px -3px rgba(#000, 0.75);

    @media print {
      box-shadow: none;
    }
  }

  &__person {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &__avatar {
      width: 30%;
      padding: 20px;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &__details {
      width: 80%;
      padding: 20px;

      &__full-name {
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        font-size: 28px;
      }

      &__cv-label {
        font-size: 12px;
        margin-bottom: 20px;
      }

      &__other {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 12px;

        &__side {
          width: 50%;

          &__detail {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            &__icon {
              margin-right: 10px;
            }

            &__lines {

              &__line {
                display: block;

                &:link, &:visited, &:active {
                  text-decoration: none;
                  color: inherit;
                }
          
                &:link:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  &__section {
    &_small {
      @media print {
        page-break-inside: avoid;
      }
    }

    &__header {
      background-color: #1C1C1C;
      color: #FFF;
      padding: 0 20px;
      overflow: hidden;

      &__wrapper {
        margin: -3px 0;
      }
    }

    &__content {

    }
  }

  &__jobs {
    &__job {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 20px 0;

      &:nth-child(odd) {
        background-color: rgba(#000, 0);
      }

      &:nth-child(even) {
        background-color: rgba(#000, 0.05);
      }

      @media print {
        page-break-inside: avoid;
      }

      &__dates {
        flex: 0 0 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrapper {
          width: 100px;
          height: 100px;
          background-color: rgba(#000, 0.2);
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &__date {
            font-family: 'Oswald', sans-serif;
            color: rgba(#000, 0.4);
          }
        }
      }

      &__details {
        flex: 1;

        &__position {
          font-family: 'Playfair Display', serif;
          font-weight: 700;
          font-size: 24px;
          padding-right: 20px;
        }

        &__location {
          font-size: 12px;
          margin-bottom: 10px;
          padding-right: 20px;
        }

        &__description {
          font-size: 14px;
          margin-bottom: 10px;
          padding-right: 20px;
        }

        &__buzzwords {
          display: flex;
          flex-wrap: wrap;
          padding-right: 20px;

          &__buzzword {
            padding: 2px 5px;
            background-color: rgba(#000, 0.2);
            color: #1C1C1C;
            font-size: 12px;
            margin: 0 5px 5px 0;
          }
        }
      }
    }
  }

  &__side-projects {

    &__side-project {
      display: block;
      padding: 10px 25px;

      &:link, &:visited, &:active {
        text-decoration: none;
        color: inherit;
      }

      &:hover {
        text-decoration: underline;
      }

      &:nth-child(odd) {
        background-color: rgba(#000, 0);
      }

      &:nth-child(even) {
        background-color: rgba(#000, 0.05);
      }

      @media print {
        page-break-inside: avoid;
      }

      &__title {
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        font-size: 24px;
      }

      &__url {
        font-size: 12px;
        opacity: 0.5;
      }

      &__description {
        font-size: 14px;
      }
    }
  }

  &__education {
    @media print {
      page-break-inside: avoid;
    }

    &__study {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 20px 0;

      &:nth-child(odd) {
        background-color: rgba(#000, 0);
      }

      &:nth-child(even) {
        background-color: rgba(#000, 0.05);
      }

      &__dates {
        flex: 0 0 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrapper {
          width: 80px;
          height: 80px;
          background-color: rgba(#000, 0.2);
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &__date {
            font-family: 'Oswald', sans-serif;
            color: rgba(#000, 0.4);
          }
        }
      }

      &__details {
        flex: 1;

        &__university {
          font-family: 'Playfair Display', serif;
          font-size: 18px;
          font-weight: 700;
          padding-right: 20px;
        }

        &__faculty {
          font-size: 16px;
          padding: 0 20px;
        }

        &__field-of-study {
          font-size: 14px;
          padding: 0 20px 0 40px;
        }
      }
    }
  }

  &__skills {
    @media print {
      page-break-inside: avoid;
    }

    &__skill {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 20px 0;

      &:nth-child(odd) {
        background-color: rgba(#000, 0);
      }

      &:nth-child(even) {
        background-color: rgba(#000, 0.05);
      }

      &__icon {
        flex: 0 0 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrapper {
          color: rgba(#000, 0.2);
          font-size: 62px;
        }
      }

      &__label {
        flex: 0 0 100px;
        font-family: 'Playfair Display', serif;
        font-size: 18px;
        font-weight: 700;
        text-align: right;
      }

      &__list {
        flex: 1;

        &__item {
          padding: 0 20px;
        }
      }
    }
  }

  &__qrcode {
    padding: 20px;
    text-align: center;
  }
}
